// TODO: expose-loader has a different syntax in Webpack 5
import $ from "expose-loader?exposes[]=$&exposes[]=jQuery!jquery";
import "bootstrap-latest/js/dist/alert";
import "bootstrap-latest/js/dist/base-component";
import "bootstrap-latest/js/dist/button";
import "bootstrap-latest/js/dist/carousel";
import "bootstrap-latest/js/dist/collapse";
import "bootstrap-latest/js/dist/dropdown";
import "bootstrap-latest/js/dist/modal";
import "bootstrap-latest/js/dist/offcanvas";
import "bootstrap-latest/js/dist/popover";
import "bootstrap-latest/js/dist/scrollspy";
import "bootstrap-latest/js/dist/tab";
import "bootstrap-latest/js/dist/toast";
import "bootstrap-latest/js/dist/tooltip";

// TODO: Update all data-* attributes to data-bs-* attributes in view code
// after Bootstrap 5 upgrade
const changeDataAttrToBs = (attr) => {
  document.querySelectorAll(`[data-${attr}]`).forEach((element) => {
    element.setAttribute(`data-bs-${attr}`, element.getAttribute(`data-${attr}`));
    element.removeAttribute(`data-${attr}`);
  });
}
const dataTags = ["toggle", "dismiss", "target", "placement", "interval", "ride", "slide-to"];
const changeDataToBs = () => {
  dataTags.forEach(changeDataAttrToBs);
}
const observer = new MutationObserver(changeDataToBs);
observer.observe(document.querySelector("body"), {
  childList: true,
  subtree: true
});
