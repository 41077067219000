import { Controller } from "@hotwired/stimulus";
import axios from "axios";
import $ from "jquery";

export default class extends Controller {
  // prefSetting value can be used when event element is
  // neither a checkbox nor an element with a value attribute.
  static values = {
    prefSetting: Boolean
  };

  // bootstrap custom-checkboxes only respond to jQuery
  connect() {
    if (this.element.className.indexOf("form-check-input") > -1) {
      $(this.element).on("change", this.update);
    }
  }

  disconnect() {
    if (this.element.className.indexOf("form-check-input") > -1) {
      $(this.element).off("change", this.update);
    }
  }

  update = (event) => {
    axios.patch("/manage/users/ui_prefs", {
      id: this.data.get("key"),
      value: this.value(event.target)
    }).then((response) => {
      this.dispatch('updated')
    });
  };

  value(element) {
    if (this.hasPrefSettingValue) {
      return this.prefSettingValue;
    } else if (typeof element.checked === "boolean") {
      return element.checked;
    } else {
      return element.value;
    }
  }
}
