import { Controller } from "@hotwired/stimulus";

/***
 *
 * Confirm a change for an individual input field using a modal and provide
 * the opportunity to revert the change if it's rejected.
 * This does not handle submiting the form, it simply controls the value of
 * the input.
 * @extends Controller
 *
 * @example <caption>Basic Usage</caption>
 *
 * <div data-controller="input-confirm">
 *   <input
 *     data-input-confirm-target="inputField"
 *     data-action="input-confirm#confirmChange">
 *   <% footer = capture do %>
       <%= button_outline_primary(
         data: {
           action: 'input-confirm#revert',
           dismiss: 'modal'
         }
       ) { 'Cancel' } %>
       <%= button_primary(
         data: {
           action: 'input-confirm#accept',
           dismiss: 'modal'
         }
       ) { 'Confirm Change' } %>
      <% end %>
      <%= render(
        layout: 'voyager/modal',
        locals: {
          data: {
            input_confirm_target: 'modal'
          },
          not_dismissible: true,
          footer: footer,
          title: "Confirm this change" }) do %>
          <div>
            Changing this field will rip a hole in the space/time continuum. Are you sure you wish to proceed?
          </div>
      <% end %>
 * </div>
 *
 *
 ***/
export default class extends Controller {
  static values = {
    debounce: { type: Number, default: 250 },
    initDelay: Number, // Used when other JS (eg. flatpickr) is setting the initial value.
    former: String
  }
  static targets = [
    "inputField",
    "modal"
  ]

  // set former value when input field connects to the dom
  inputFieldTargetConnected() {
    if (this.hasInitDelayValue) {
      setTimeout(()=> { this.setFormerValue() }, this.initDelayValue);
    } else {
      this.setFormerValue()
    }
  }

  confirmChange() {
    clearTimeout(this.confirmTimer)
    this.confirmTimer = setTimeout(()=>{ this.askForConfirmation()}, this.debounceValue);
  }

  accept() {
    this.setFormerValue()
  }

  revert() {
    this.inputFieldTarget.value = this.formerValue
    if (this.inputFieldTarget._flatpickr) {
      this.inputFieldTarget._flatpickr.setDate(this.formerValue)
    }
  }

  // Private

  askForConfirmation() {
    $(this.modalTarget).modal('show')
  }

  setFormerValue() {
    this.formerValue = this.inputFieldTarget.value
  }
}
