import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    backupCodes: Array
  };

  download_backup_codes() {
    let csv = this.backupCodesValue.toString() + "\n";

    let hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.target = '_blank';

    hiddenElement.download = 'CP_Backup_Codes.csv';
    hiddenElement.click();
  }
}
